<template>
	<div class="main">
    <img class="bg" src="https://cdn.kuaidi100.com/images/better/dbp/bg.png">
    <div class="desc">
      <div class="desc-top">包裹丢了怎么办？</div>
      <div class="desc-bottom">快递公司理赔流程复杂？相互推卸责任？</div>
    </div>
    <div class="noworry">
      <div class="noworry-main">
        <span>不用担心</span>
        <div>
          <img class="noworry-smile" src="https://cdn.kuaidi100.com/images/better/dbp/smile.png">
        </div>
        <img class="noworry-arrow" src="https://cdn.kuaidi100.com/images/better/dbp/arrow.png">
      </div>
    </div>
    <div class="word">
      <div class="word-top">用快递100寄快递，极速下单</div>
      <div class="word-bottom"><span style="font-size:1.1875rem;">免费</span>提供最高1000元保价服务，平台直接理赔</div>
    </div>
    <div class="tips">
      温馨提示：根据真实有效的货物价值进行理赔，最高不超过1000元
    </div>
    <div class="noworry">
      <div>轻松理赔</div>
    </div>
    <div class="step">
      <div class="step-main">
      <img class="step-main-arrow"
        src="https://cdn.kuaidi100.com/images/better/dbp/left_arrow.png">
      <img class="step-main-icon" 
        src="https://cdn.kuaidi100.com/images/all/144/kuaidi100.png"><div class="step-main-word">快递100</div><div>寄快递</div>
      </div>
      <div class="step-main">
      <img class="step-main-arrow"
        src="https://cdn.kuaidi100.com/images/better/dbp/left_arrow.png">
      <img class="step-main-icon"
      src="https://cdn.kuaidi100.com/images/better/dbp/icon-2.png"><div class="step-main-word">包裹出现</div><div>意外</div>
      </div>
      <div class="step-main">
      <img class="step-main-arrow"
        src="https://cdn.kuaidi100.com/images/better/dbp/left_arrow.png">
      <img class="step-main-icon" 
        src="https://cdn.kuaidi100.com/images/better/dbp/icon-3.png"><div class="step-main-word" style="color: #317ee7;font-weight: bold;">申请理赔</div>
      </div>
      <div class="step-main">
      <img class="step-main-arrow"
        src="https://cdn.kuaidi100.com/images/better/dbp/left_arrow.png">
      <img class="step-main-icon"
        src="https://cdn.kuaidi100.com/images/better/dbp/icon-4.png"><div class="step-main-word">专线客服</div>
      </div>
      <div class="step-main"><img class="step-main-icon"
        src="https://cdn.kuaidi100.com/images/better/dbp/icon-5.png"><div class="step-main-word">赔付到账</div>
      </div>
    </div>
    <div class="apply">
      <img src="https://cdn.kuaidi100.com/images/better/dbp/arrow.png">
      <div>可以在app/小程序—我的订单—订单详情中</div>
      <div>申请并联系客服</div>
    </div>
    <div class="last">
      <div>平台已赠送“丢必赔”服务，上门取件时请勿再给快递员保价费</div>
      <div>（超过千元，仍需按完整价格进行保价）</div>
    </div>
    <div class="btn"><button @click="goUrl">我知道了</button></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    components: {
			
    },
    onLoad (e) {
      this.know = e.know
    },
		data() {
			return {
        know: false
			}
    },
    onReady () {
      
    },
    created (){
      
    },
    mounted () {
      
    },
    methods: {
      goUrl () {
        this.$router.back(-1)
      }
    }
	}
</script>

<style lang="scss" scoped>
  .tips {
    margin-top: 50px;
    background: #fef1e3;
    font-size: 13px;
    color: #ff7f02;
    width: 331px;
    margin: 10px auto;
    padding: 4px 6px;
    position: relative;
    text-align: left;
  }
  .tips::before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 10px solid #fef1e3;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: -9px;
    left: 50%;
    margin-left: -7px;
  }
  .main {
    background: #fff;
    width: 100%;
    text-align: center;
  }

  .bg {
    width: 100%;
    height: 12.8125rem;
  }

  .desc {
    color: #000;
    font-size: 0.75rem;
    text-align: center;
  }
  .noworry {
    &-main {
      font-size: 1rem;
      color: #333;
      margin-top: 1.0625rem;
      font-weight: bold;
    }
  }

  .noworry-smile {
    width: 2.1875rem;
    height: 0.5625rem;
  }

  .noworry-arrow {
    transform: rotate(180deg);
    width: 0.6875rem;
    height: 0.6875rem;
    margin-top: 0.5rem;
  }

  .word {
    border: 1px dashed #317EE7;
    width: 20.6875rem;
    margin: 0 auto;
    background: #F2F8FF;
    color: #333;
    height: 3.5625rem;
    padding-top: 1.0625rem;
    margin-top:  0.3125rem;
  }

  .word-top {
    font-size: 0.875rem;
  }

  .word-bottom {
    font-size: 0.875rem;
    color: #ff7f02;
  }

  .step {
    margin-top: 1.5rem;
    height: 14rem;
  }

  .step-main {
    width: 2.5rem;
    font-size: 0.75rem;
    color: #888;
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    margin: 0 1rem;
  }

  .step-main div{
    width: 3.125rem;
    margin-left: -0.3125rem;
  }

  .step-main .step-main-icon{
    border-radius: 50%;
    width: 2.1875rem;
    height: 2.1875rem;
  }

  .step-main-word {
    margin-top: 0.3125rem;
  }

  .step-main-arrow {
    width: 0.9375rem;
    height: 0.5rem;
    position: absolute;
    top: 0.9375rem;
    right: -1.5rem;
  }

  .apply {
    background: #E8F1FF;
    font-size: 0.875rem;
    color: #317ee7;
    width: 20.6875rem;
    margin: 0.625rem auto;
    padding: 0.78125rem 0;
    position: relative;
  }

  .apply::before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 0.625rem solid #E8F1FF;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    position: absolute;
    top: -0.5625rem;
    left: 50%;
    margin-left: -0.4375rem;
  }

  .apply img{
    width: 0.6875rem;
    height: 0.6875rem;
    position: absolute;
    left: 50%;
    top: -1.25rem;
    margin-left: -0.34375rem;
  }
  .last {
    background: #efeff4;
    color: #888;
    font-size: 0.625rem;
    text-align: center;
    padding-top: 0.625rem;
    padding-bottom: 6.25rem;
  }
  .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efeff4;
    z-index: 1000;
  }
  .btn button {
    width: 20.875rem;
    height: 2.75rem;
    font-size: 1.0625rem;
    color: #fff;
    border-radius: 0.25rem;
    background:  #ff7f02;
    text-align: center;
  }
</style>
